<template>
  <v-container fluid>
    <!-- Additional Information or Actions -->
    <v-row justify="center">
      <v-col cols="12" md="12" lg="12" class="px-0 py-0 mb-10">
        <span style="font-size: 12px !important;">
          To Buy or Sell your bread points, go to Marketplace.
        </span>
        <!-- Withdrawal Request History -->
        <v-card class="mt-1" elevation="5">
          <v-list dense>
            <v-subheader>Withdrawal Request History</v-subheader>
            <v-list-item-group v-if="withdrawalRequests.length > 0">
              <v-list-item v-for="withdrawal in withdrawalRequests" :key="withdrawal.id" style="border-bottom: 1px solid #ecb68a !important;">
                <!-- Display withdrawal request details -->
                <v-list-item-content>
                  <v-list-item-title>
                    <!-- Display different color chips based on the withdrawal status -->
                    <v-list-item-icon>
                      <v-chip :color="getChipColor(withdrawal.withdrawalStatus)" dark>
                        {{ withdrawal.withdrawalStatus === 'pending' ? 'Pending' : 'Paid' }}
                      </v-chip>
                    </v-list-item-icon>
                    <!-- Display other withdrawal request details -->
                    <div>
                      <span class="transaction-description">{{ withdrawal.description }}</span>
                      <div class="transaction-details">
                        <span class="transaction-info">Amount: {{ parseFloat(withdrawal.amount).toLocaleString('en-US') }}pcs</span> ~ 
                        <span class="transaction-info"> Bank: {{ withdrawal.bankName }}</span><br/>
                        <span class="transaction-info">Account Number: {{ withdrawal.accountNumber }}</span> ~
                        <span class="transaction-info">Account Name: {{ withdrawal.accountName }}</span><br/>
                        <span class="transaction-info">Reference: {{ withdrawal.transactionRef }}</span><br/>
                        <span class="transaction-info">Date: {{ new Date(withdrawal.createdAt) }}</span>
                      </div>
                      <!-- Display "Pay User" button for pending requests -->
                      <v-btn v-if="withdrawal.withdrawalStatus === 'pending'" @click="payUser(withdrawal)" color="primary">Pay User</v-btn> &nbsp; | &nbsp; | &nbsp;
                      <v-btn v-if="withdrawal.withdrawalStatus === 'pending'" @click="cancelWithdrawal(withdrawal)" color="error">Cancel</v-btn>
                      
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>No withdrawal requests available.</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-btn align="center" v-if="showViewMoreButton" @click="loadMoreWithdrawalRequests" class="mt-2">View More Withdrawal Requests</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showViewMoreButton: false,
      withdrawalRequests: [
        //hello
      ],
      page: 1
    }
  },
  created() {
    this.$store.commit('loading/setLoading', true, { root: true })
    this.loadWithdrawalRequests()
  },
  methods: {
    getChipColor(status) {
      return status === 'pending' ? 'burlywood' : 'green'
    },
    async loadWithdrawalRequests() {
      try {
        const credentials = {
          userProfile: this.$store.getters['auth/userInfo'],
          page: this.page
        }

        console.log('we need to get pending withdrawals')
        const response = await this.$store.dispatch('wallet/getPendingWithdrawalRequest', credentials)

        if (response && response.success) {
          if (response.success) {
            console.log('we are here o, below are the requests ', response)
            // Use the spread syntax to merge existing withdrawal requests with new ones
            this.withdrawalRequests = [...this.withdrawalRequests, ...response.withdrawals]
            // Check if there are more withdrawal requests to load
            //this.showViewMoreButton = response.data.length > 0
            this.showViewMoreButton = response.totalCount > this.withdrawalRequests.length
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.commit('loading/setLoading', false, { root: true })
      } 
    },
    async loadMoreWithdrawalRequests() {
      this.page += 1
      this.loadWithdrawalRequests()
    },
    async payUser(withdrawal) {
      try {
        
        console.log('we need to get pending withdrawals')
        const response = await this.$store.dispatch('wallet/sellUserBread', withdrawal)

        if (response && response.success) {
          if (response.success) {
            withdrawal.withdrawalStatus = 'completed'
            this.$store.dispatch('snackbar/showSnackbar', {
              message: response.message,
              variant: 'black' // Optional, defaults to 'info'
            })
          }
        } else {
          this.$store.dispatch('snackbar/showSnackbar', {
            message: response.message,
            variant: 'black' // Optional, defaults to 'info'
          })
        }
      } catch (error) {
        //console.error(error)
        this.$store.dispatch('snackbar/showSnackbar', {
          message: error.message,
          variant: 'black' // Optional, defaults to 'info'
        })
      } finally {
        this.$store.commit('loading/setLoading', false, { root: true })
      }
    },
    async cancelWithdrawal(withdrawal) {
      try {
        const response = await this.$store.dispatch('wallet/cancelWithdrawalRequest', withdrawal)
        
        if (response && response.success) {
          withdrawal.withdrawalStatus = 'cancelled'
          this.$store.dispatch('snackbar/showSnackbar', {
            message: 'Withdrawal request cancelled successfully.',
            variant: 'success'
          })
        } else {
          this.$store.dispatch('snackbar/showSnackbar', {
            message: 'Failed to cancel withdrawal request. Please try again later.',
            variant: 'error'
          })
        }
      } catch (error) {
        console.error('Error cancelling withdrawal request:', error)
      }
    }
  }
}
</script>

<style scoped>
/* Add custom styles if needed */

.wallet-amount {
  font-size: 25px;
  font-weight: bold;
}

.visible {
  display: block;
}

.transaction-description {
  font-weight: bold;
  font-size: 1.0em;
  white-space: normal;
}

.transaction-details {
  margin-top: 5px;
  font-size: 13px !important;
}

.transaction-info {
  font-size: 0.9em;
}
</style>